import React, { createContext, useState, ReactNode } from 'react';

export type IndustryName = 'mining' | 'agriculture';

const DEFAULT = 'mining' as IndustryName;

const IndustryContext = createContext({
  industry: DEFAULT,
  // eslint-disable-next-line
  setIndustry: (a : IndustryName) => {},
});

export const Provider = ({ children } : {children: ReactNode}) => {
  const [industry, setIndustry] = useState(DEFAULT);

  return (
    <IndustryContext.Provider value={{ industry, setIndustry }}>
      {children}
    </IndustryContext.Provider>
  );
};

export default IndustryContext;
